<template>
  <v-container>
    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        right
        top
        color="green darken-2"
    >
      Copied : {{ snackbarText }}
    </v-snackbar>
    <v-dialog v-model="isVisibleValidationErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ errorMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <CampaignEditorTonic
        v-if="isVisibleEditorDialog && isTonicNetwork"
        v-model="isVisibleEditorDialog"
        :users="users"
        :countries="countries"
        :campaign="editCampaign"
        :tz="tz"
        :tab="tab"
        :offers="offers"
        :selected-country="country"
        :state="state"
        :copyKeywords="copyKeywords"
        :copyKeywordsFromCampaignId="copyKeywordsFromCampaignId"
        @created="getPendingCampaigns"
        @update="getCampaigns"
        @copy-keywords="copyKeywordsToId"
    ></CampaignEditorTonic>

    <CampaignEditorDomainActive
        v-if="isVisibleEditorDialog && isDomainActiveNetwork"
        v-model="isVisibleEditorDialog"
        :users="users"
        :countries="countries"
        :campaign="editCampaign"
        :tz="tz"
        :tab="tab"
        :offers="offers"
        :selected-country="country"
        :state="state"
        @created="getPendingCampaigns"
        @update="getCampaigns"
    ></CampaignEditorDomainActive>
    <v-row v-if="networkIsEmpty">
      <v-col cols="12" sm="12" md="12">
        Select Network
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" sm="2">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="430px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                label="Date or Date range"
                v-model="dateRangeText"
                prepend-icon="mdi-calendar"
                @click:prepend="menu = true"
                readonly
                dense
                solo
                hide-details
                v-bind="attrs"
                v-on="on"
            ></v-text-field>

          </template>
          <v-card flat>
            <v-row no-gutters>
              <v-col cols="4">

                <v-list dense>
                  <v-subheader class="justify-center">
                    <v-icon>mdi-calendar-range-outline</v-icon>
                  </v-subheader>
                  <v-list-item-group
                      v-model="selectedFastDateRange"
                      color="primary"
                  >
                    <v-list-item
                        v-for="(item, i) in selectedFastDateRangeItems"
                        :key="i"
                    >
                      <v-list-item-content @click="onClickFastDateInterval(item)">
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>

                </v-list>
              </v-col>
              <v-col cols="8">
                <v-date-picker
                    v-model="dates"
                    :show-current="todayDate()"
                    reactive
                    first-day-of-week="1"
                    range
                ></v-date-picker>
                <!--                  <v-spacer></v-spacer>-->
                <div class="text-right">
                  <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="onclickOkPeekDate()"
                  >
                    OK
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>

        </v-menu>
      </v-col>
      <v-col cols="12" sm="2">
        <v-select
            v-model="groupBy"
            :items="groupByItems"
            hide-details
            dense
            solo
            @change="changeGroupBy"
            label="State"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-select
            v-model="state"
            :items="states"
            hide-details
            dense
            solo
            @change="changeState"
            label="Group By"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-autocomplete
            v-model="user"
            :items="users"
            hide-details
            dense
            solo
            @change="changeUser"
            label="User"
            clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2">
        <v-autocomplete
            v-model="country"
            :items="countries"
            hide-details
            dense
            solo
            item-value="code"
            @change="changeCountry"
            label="Country"
            clearable
        >
          <template v-slot:prepend>
            <flag :iso="country"/>
          </template>

          <template v-slot:item="{ item }">
            <flag :iso="item.code" class="mr-3"/>
            {{ item.text }}
          </template>

        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" v-if="isTonicNetwork">

        <v-autocomplete
            v-model="offer"
            :items="offers"
            hide-details
            dense
            solo
            item-value="id"
            item-text="name"
            @change="changeOffer"
            label="Offer"
            clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2" v-else>
      </v-col>
      <!--      <v-row>-->
      <!--        -->
      <!--      </v-row>-->
      <!--      <v-row>-->
      <!--        -->
      <!--      </v-row>-->
      <v-col cols="12" sm="2">
        <v-text-field
            v-model="id"
            hide-details
            dense
            solo
            @change="onChangeId"
            @click:clear.stop="onChangeId"
            label="ID"
            clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
            v-model="name"
            hide-details
            dense
            solo
            @change="onChangeName"
            @click:clear.stop="onChangeName"
            label="Name"
            clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
            v-model="link"
            hide-details
            dense
            solo
            @change="onChangeLink"
            @click:clear.stop="onChangeLink"
            label="Link"
            clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="3" v-if="paginationVisible">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </v-col>
      <v-col cols="12" sm="1" v-if="paginationVisible">
        <v-select
            v-model="itemsPerPage"
            :items="itemsPerPageOptions"
            hide-details
            dense
            solo
        ></v-select>
      </v-col>

      <v-col cols="12" sm="1" v-if="perPageVisible">
        <v-select
            v-model="itemsPerPage"
            :items="itemsPerPageOptions"
            hide-details
            dense
            solo
        ></v-select>
      </v-col>

      <v-col cols="12" sm="12" md="12" v-if="chartIsReady">
        <v-row>
          <v-col cols="12" sm="12">
            <highcharts class="stock" :options="highchartsOptions"></highcharts>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="12" md="12" v-if="groupBy === 'keyword' || groupBy === 'offer'">
        <v-data-table
            :class="paginationVisible || chartIsReady ? 'row-pointer' : ''"
            fixed-header
            hide-default-footer
            @click:row="onClickRow"
            @current-items="onCurrentItems"
            @page-count="pageCount = $event"
            :headers="headers"
            :options.sync="options"
            :server-items-length="campaignsTotal"
            :items="campaigns"
            :item-key="groupBy"
            :loading="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :page.sync="page"
            v-model="selectedRows"
        >

          <template v-slot:body.prepend>
            <tr>

              <td>
                <b>Total</b>
              </td>
              <td v-if="paginationVisible"></td>
              <td v-if="paginationVisible"></td>
              <td v-if="paginationVisible"></td>
              <td v-if="paginationVisible"></td>
              <td v-if="paginationVisible && isTonicNetwork"></td>
              <td v-if="paginationVisible && isTonicNetwork"></td>
              <td><b>{{ totalClicks }}</b></td>
              <td v-if="isDomainActiveNetwork && groupBy === 'keyword'"><b>{{ totalLanderVisitor }}</b></td>
              <td><b>{{ Math.round(totalRevenue * 100) / 100 }} $</b></td>
              <td><b>{{ Math.round((totalRevenue / totalClicks) * 100) / 100 }}</b></td>
              <td v-if="isDomainActiveNetwork && groupBy === 'keyword'">><b>{{ Math.round((totalClicks/totalLanderVisitor) * 100) / 100 }}</b></td>
            </tr>
          </template>

          <template v-slot:item="{ item }">
            <tr :class="selectedRows.indexOf(item[groupBy])>-1?'grey lighten-2':''" @click="onClickRow(item)">
              <td v-if="groupBy === 'keyword'">
                <v-icon small @click.stop="copyToClipboard(item[groupBy].toString())">
                  mdi-content-copy
                </v-icon>
                {{ item[groupBy] }}
              </td>
              <td v-else>{{ item[groupBy] }}</td>
              <td>{{ item.clicks }}</td>
              <td v-if="isDomainActiveNetwork && groupBy === 'keyword'">{{ item.landerVisitor }}</td>
              <td>{{ item.revenue }}</td>
              <td>{{ item.cpc }}</td>
              <td v-if="isDomainActiveNetwork && groupBy === 'keyword'">{{ item.ctr }}</td>
            </tr>
          </template>

        </v-data-table>
      </v-col>

      <v-col cols="12" sm="12" md="12" v-else>
        <v-data-table
            :class="paginationVisible || chartIsReady ? 'row-pointer' : ''"
            fixed-header
            hide-default-footer
            @click:row="onClickRow"
            @current-items="onCurrentItems"
            @page-count="pageCount = $event"
            :headers="headers"
            :options.sync="options"
            :server-items-length="campaignsTotal"
            :items="campaigns"
            :item-key="groupBy"
            :loading="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :page.sync="page"
            v-model="selectedRows"
        >

          <template v-slot:body.prepend>
            <tr>

              <td>
                <b>Total</b>
              </td>
              <td v-if="paginationVisible"></td>
              <td v-if="paginationVisible"></td>
              <td v-if="paginationVisible"></td>
              <td v-if="paginationVisible"></td>
              <td v-if="paginationVisible  && isTonicNetwork"></td>
              <td v-if="paginationVisible  && isTonicNetwork"></td>
              <td v-if="paginationVisible  && isTonicNetwork"></td>
              <td><b>{{ totalClicks }}</b></td>
              <td><b>{{ Math.round(totalRevenue * 100) / 100 }} $</b></td>
              <td><b>{{ Math.round((totalRevenue / totalClicks) * 100) / 100 }}</b></td>
            </tr>
          </template>

          <template v-slot:item.id="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on" @click.stop="copyToClipboard(item.id)">{{ item.id }}</span>
              </template>
              <span>Click to copy ID</span>
            </v-tooltip>
          </template>

          <template v-slot:item.country="{ item }">
            <flag :iso="item.country" class="mr-3"/>
            {{ item.country }}
          </template>

          <template v-slot:item.link="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <!--                <span v-on="on" @click.stop="copyToClipboard('https://'+item.link)">{{ item.link }}</span>-->
                <v-icon v-on="on" small @click.stop="copyToClipboard('https://'+item.link)" v-if="isTonicNetwork">
                  mdi-content-copy
                </v-icon>

                <v-icon v-on="on" small @click.stop="copyToClipboardLink(item)" v-if="isDomainActiveNetwork">
                  mdi-content-copy
                </v-icon>
              </template>
              <span>Click to copy URL</span>
            </v-tooltip>
          </template>

          <template v-slot:item.direct_link="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <!--                <span v-on="on" @click.stop="copyToClipboard('https://'+item.link)">{{ item.link }}</span>-->
                <v-icon v-on="on" small @click.stop="copyToClipboard(item.direct_link)" v-if="isTonicNetwork">
                  mdi-content-copy
                </v-icon>
              </template>
              <span>Click to copy URL</span>
            </v-tooltip>
          </template>

          <template v-slot:item.clicks="{ item }">
            <v-tooltip bottom v-if="paginationVisible">
              <template v-slot:activator="{ on }">
                <span v-on="on" @click.stop="onClickStatistic(item)">{{ item.clicks }}</span>
              </template>
              <span>Click to open Chart</span>
            </v-tooltip>
            <span v-else>
              {{ item.clicks }}
            </span>
          </template>

          <template v-slot:item.revenue="{ item }">
            <v-tooltip bottom v-if="paginationVisible">
              <template v-slot:activator="{ on }">
                <span v-on="on" @click.stop="onClickStatistic(item)">{{ item.revenue }} $</span>
              </template>
              <span>Click to open Chart</span>
            </v-tooltip>
            <span v-else>{{ item.revenue }}$</span>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/utils/api'
// import CampaignEditor from './CampaignEditor'
import CampaignEditorTonic from './CampaignEditor/CampaignEditorTonic'
import CampaignEditorDomainActive from './CampaignEditor/CampaignEditorDomainActive'
import {DateTime} from 'luxon'
import {
  IFACE_AFFNETS_REFRESH_OFF, IFACE_AFFNETS_SET_FINALIZEDDATES,
  IFACE_AFFNETS_TABLE_LOADING_OFF, IFACE_AFFNETS_TABLE_LOADING_ON,
} from "@/store/actions/iface";
import {
  IFACE_AFFNETS_ADD_ITEM_WINDOW_OFF,
  IFACE_AFFNETS_ADD_ITEM_WINDOW_ON,
} from "@/store/actions/iface";
import Highcharts from "highcharts";

export default {
  name: 'AffNetworks',
  components: {CampaignEditorTonic, CampaignEditorDomainActive},

  data() {
    return {
      tz: 'America/Los_Angeles',
      isVisibleValidationErrorDialog: false,
      errorMessage: '',

      groupByItems: [
        {text: 'Date', value: 'date'},
        {text: 'Country', value: 'country'},
        {text: 'User', value: 'user'},
        {text: 'ID', value: 'id'},
        {text: 'Keyword', value: 'keyword'},
        {text: 'Offer', value: 'offer'},
      ],

      groupBy: 'id',
      // groupBy: 'offer',

      snackbar: false,
      snackbarText: '',

      isVisibleEditorDialog: false,

      editCampaign: null,

      tab: 'tab-info',

      networkIsEmpty: false,

      paginationVisible: true,
      perPageVisible: false,
      page: 1,
      pageCount: 0,
      options: {itemsPerPage: 10},

      selectedRows: [],

      totalOffers: 0,
      offers: [],
      offer: null,

      default_domain: '',

      campaignsTotal: 0,
      campaigns: [],

      totalClicks: 0,
      totalLanderVisitor: 0,
      totalRevenue: 0,

      campaignsCurrentItems: [],

      // loading: false,
      itemsPerPage: 10,
      itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
      sortBy: ['id'],
      sortDesc: [false],
      headers: null,
      copyKeywords: [],
      copyKeywordsFromCampaignId: null,

      // headersById: [
      //   {text: 'ID', align: 'start', value: 'id', class: 'sticky-header'},
      //   {text: 'Name', align: 'start', value: 'name', class: 'sticky-header'},
      //   {text: 'Vertical', align: 'start', value: 'vertical', class: 'sticky-header'},
      //   {text: 'Offer', align: 'start', value: 'offer', class: 'sticky-header'},
      //   {text: 'Country', align: 'start', value: 'country', class: 'sticky-header'},
      //   {text: 'Link', align: 'start', value: 'link', sortable: false, class: 'sticky-header'},
      //   {text: 'Imprint', align: 'start', value: 'imprint', class: 'sticky-header'},
      //   {text: 'Clicks', align: 'start', value: 'clicks', class: 'sticky-header'},
      //   {text: 'Revenue, $', align: 'start', value: 'revenue', class: 'sticky-header'},
      //   {text: 'CPC, $', align: 'start', value: 'cpc', class: 'sticky-header'},
      // ],

      headersByIdTonic: [
        {text: 'ID', align: 'start', value: 'id', class: 'sticky-header'},
        {text: 'Name', align: 'start', value: 'name', class: 'sticky-header'},
        {text: 'Vertical', align: 'start', value: 'vertical', class: 'sticky-header'},
        {text: 'Offer', align: 'start', value: 'offer', class: 'sticky-header'},
        {text: 'Country', align: 'start', value: 'country', class: 'sticky-header'},
        {text: 'Link', align: 'start', value: 'link', sortable: false, class: 'sticky-header'},
        {text: 'D.Link', align: 'start', value: 'direct_link', sortable: false, class: 'sticky-header'},
        {text: 'Imprint', align: 'start', value: 'imprint', class: 'sticky-header'},
        {text: 'Clicks', align: 'start', value: 'clicks', class: 'sticky-header'},
        {text: 'Revenue, $', align: 'start', value: 'revenue', class: 'sticky-header'},
        {text: 'CPC, $', align: 'start', value: 'cpc', class: 'sticky-header'},
      ],
      headersByIdDomainActive: [
        {text: 'ID', align: 'start', value: 'id', class: 'sticky-header'},
        {text: 'Name', align: 'start', value: 'name', class: 'sticky-header'},
        // {text: 'Vertical', align: 'start', value: 'vertical', class: 'sticky-header'},
        // {text: 'Offer', align: 'start', value: 'offer', class: 'sticky-header'},
        {text: 'Country', align: 'start', value: 'country', class: 'sticky-header'},
        {text: 'Link', align: 'start', value: 'link', sortable: false, class: 'sticky-header'},
        {text: 'Imprint', align: 'start', value: 'imprint', class: 'sticky-header'},
        {text: 'Clicks', align: 'start', value: 'clicks', class: 'sticky-header'},
        {text: 'Revenue, $', align: 'start', value: 'revenue', class: 'sticky-header'},
        {text: 'CPC, $', align: 'start', value: 'cpc', class: 'sticky-header'},
      ],

      headersByUser: [
        {text: 'User', align: 'start', value: 'user', class: 'sticky-header'},
        {text: 'Clicks', align: 'start', value: 'clicks', sortable: false, class: 'sticky-header'},
        {text: 'Revenue, $', align: 'start', value: 'revenue', sortable: false, class: 'sticky-header'},
        {text: 'CPC, $', align: 'start', value: 'cpc', sortable: false, class: 'sticky-header'},
      ],

      headersByCountry: [
        {text: 'Country', align: 'start', value: 'country', class: 'sticky-header'},
        {text: 'Clicks', align: 'start', value: 'clicks', sortable: false, class: 'sticky-header'},
        {text: 'Revenue, $', align: 'start', value: 'revenue', sortable: false, class: 'sticky-header'},
        {text: 'CPC, $', align: 'start', value: 'cpc', sortable: false, class: 'sticky-header'},
      ],

      headersByDate: [
        {text: 'Date', align: 'start', value: 'date'},
        {text: 'Clicks', align: 'start', value: 'clicks', sortable: false, class: 'sticky-header'},
        {text: 'Revenue, $', align: 'start', value: 'revenue', sortable: false, class: 'sticky-header'},
        {text: 'CPC, $', align: 'start', value: 'cpc', sortable: false, class: 'sticky-header'},
      ],

      headersByKeyword: [
        {text: 'Keyword', align: 'start', value: 'keyword', class: 'sticky-header'},
        {text: 'Clicks', align: 'start', value: 'clicks', sortable: true, class: 'sticky-header'},
        {text: 'Revenue, $', align: 'start', value: 'revenue', sortable: true, class: 'sticky-header'},
        {text: 'CPC, $', align: 'start', value: 'cpc', sortable: true, class: 'sticky-header'},
      ],

      headersByKeyword_DomainActive: [
        {text: 'Keyword', align: 'start', value: 'keyword', class: 'sticky-header'},
        {text: 'Clicks', align: 'start', value: 'clicks', sortable: true, class: 'sticky-header'},
        {text: 'Lander Visits', align: 'start', value: 'landerVisitor', sortable: true, class: 'sticky-header'},
        {text: 'Revenue, $', align: 'start', value: 'revenue', sortable: true, class: 'sticky-header'},
        {text: 'CPC, $', align: 'start', value: 'cpc', sortable: true, class: 'sticky-header'},
        {text: 'CTR, %', align: 'start', value: 'ctr', sortable: true, class: 'sticky-header'},
      ],

      headersByOffer: [
        {text: 'Offer', align: 'start', value: 'offer', class: 'sticky-header'},
        {text: 'Clicks', align: 'start', value: 'clicks', sortable: false, class: 'sticky-header'},
        {text: 'Revenue, $', align: 'start', value: 'revenue', sortable: false, class: 'sticky-header'},
        {text: 'CPC, $', align: 'start', value: 'cpc', class: 'sticky-header'},
      ],

      countries: [],
      country: null,

      id: null,
      name: null,
      link: null,

      states: [
        {text: 'All', value: 'all'},
        {text: 'Active', value: 'active'},
        {text: 'Pending', value: 'pending'},
        {text: 'Stopped', value: 'stopped'},
        {text: 'Incomplete', value: 'incomplete'},
        {text: 'Deleted', value: 'deleted'}
      ],

      state: 'active',

      users: [],
      user: null,

      menu: false,

      selectedFastDateRange: 1,
      selectedFastDateRangeItems: [
        {text: 'Today', value: 'today'},
        {text: 'Yesterday', value: 'yesterday'},
        {text: 'Lats 3 days', value: 'lats3days'},
        {text: 'Lats 7 days', value: 'lats7days'},
        {text: 'This month', value: 'thisMonth'},
        {text: 'Last month', value: 'lastMonth'},
      ],

      dateRangeText: null,
      dates: [],
      pickerDate: null,

      chartIsReady: false,

      highchartsOptions: null,

      highchartsForSingleOptions: {
        chart: {
          zoomType: 'xy'
        },
        credits: {
          enabled: false
        },
        title: null,
        subtitle: null,
        xAxis: [{
          categories: [],
          crosshair: true
        }],
        yAxis: [

          { // Secondary yAxis
            title: {
              text: 'Revenue',
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            labels: {
              format: '{value} $',
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },

          },
          { // Primary yAxis
            labels: {
              format: '{value}',
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            title: {
              text: 'CPC',
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || // theme
              'rgba(255,255,255,0.25)'
        },
        series: [
          {
            name: 'Revenue',
            type: 'column',
            data: [],
            tooltip: {
              valueSuffix: ' $'
            }

          },
          {
            name: 'CPC',
            type: 'spline',
            yAxis: 1,
            data: [],
            tooltip: {
              valueSuffix: ''
            }
          },
          {
            name: 'Clicks',
            type: 'spline',
            data: [],
            tooltip: {
              valueSuffix: ''
            }
          },
        ]
      },
      highchartsForSingleOptions_DA: {
        chart: {
          zoomType: 'xy'
        },
        credits: {
          enabled: false
        },
        title: null,
        subtitle: null,
        xAxis: [{
          categories: [],
          crosshair: true
        }],
        yAxis: [

          { // Secondary yAxis
            title: {
              text: 'Revenue',
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            labels: {
              format: '{value} $',
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },

          },
          { // Primary yAxis
            labels: {
              format: '{value}',
              style: {
                color: Highcharts.getOptions().colors[2]
              }
            },
            title: {
              text: 'CPC',
              style: {
                color: Highcharts.getOptions().colors[2]
              }
            },
            opposite: true
          },
          { // Secondary yAxis
            title: {
              text: 'Clicks',
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            labels: {
              format: '{value}',
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
          },
          { // Primary yAxis
            labels: {
              format: '{value}',
              style: {
                color: Highcharts.getOptions().colors[3]
              }
            },
            title: {
              text: 'CTR, %',
              style: {
                color: Highcharts.getOptions().colors[3]
              }
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || // theme
              'rgba(255,255,255,0.25)'
        },
        series: [
          {
            name: 'Revenue',
            type: 'column',
            data: [],
            tooltip: {
              valueSuffix: ' $'
            }

          },
          {
            name: 'CPC',
            type: 'spline',
            yAxis: 1,
            data: [],
            tooltip: {
              valueSuffix: ''
            }
          },
          {
            name: 'Clicks',
            type: 'spline',
            yAxis: 2,
            data: [],
            tooltip: {
              valueSuffix: ''
            }
          },
          {
            name: 'CTR',
            type: 'spline',
            data: [],
            yAxis: 3,
            tooltip: {
              valueSuffix: ' %'
            }
          }
        ]
      },

      // highchartsForSingleOptions: {
      //   chart: {
      //     zoomType: 'xy'
      //   },
      //   credits: {
      //     enabled: false
      //   },
      //   title: null,
      //   subtitle: null,
      //   xAxis: {
      //     categories: null,
      //     accessibility: {
      //       description: 'Months of the year'
      //     },
      //     crosshair: true
      //   },
      //   yAxis: [
      //     {
      //       title: {
      //         text: 'Revenue',
      //         style: {
      //           color: Highcharts.getOptions().colors[0]
      //         }
      //       },
      //       labels: {
      //         format: '{value} $',
      //         style: {
      //           color: Highcharts.getOptions().colors[0]
      //         }
      //       }
      //     },
      //     {
      //       title: {
      //         text: 'Clicks',
      //         style: {
      //           color: Highcharts.getOptions().colors[1]
      //         }
      //       },
      //       labels: {
      //         format: '{value}',
      //         style: {
      //           color: Highcharts.getOptions().colors[1]
      //         }
      //       },
      //       opposite: true,
      //       // linkedTo: 0,
      //       // gridLineWidth: 0,
      //     }
      //     ],
      //   tooltip: {
      //     shared: true
      //   },
      //   // plotOptions: {
      //   //   spline: {
      //   //     marker: {
      //   //       radius: 4,
      //   //       lineColor: '#666666',
      //   //       lineWidth: 1
      //   //     }
      //   //   }
      //   // },
      //   legend: {
      //     layout: 'vertical',
      //     align: 'right',
      //     verticalAlign: 'middle',
      //     backgroundColor:
      //         Highcharts.defaultOptions.legend.backgroundColor || // theme
      //         'rgba(255,255,255,0.25)'
      //   },
      //   series: [
      //     {
      //       name: 'Revenue',
      //       type: 'spline',
      //       yAxis: 0,
      //       data: [],
      //       tooltip: {
      //         valueSuffix: ' $'
      //       }
      //
      //     },
      //     {
      //       name: 'Clicks',
      //       type: 'spline',
      //       data: [],
      //       tooltip: {
      //         valueSuffix: ' x'
      //       }
      //     },
      //     // {
      //     //   name: 'CPC',
      //     //   type: 'column',
      //     //   data: [],
      //     //   tooltip: {
      //     //     valueSuffix: ''
      //     //   }
      //     // }
      //     ]
      // },

      highchartsForAllOptions: {
        chart: {
          type: 'spline'
        },
        credits: {
          enabled: false
        },
        title: null,
        subtitle: null,
        xAxis: {
          categories: null,
          accessibility: {
            description: 'Months of the year'
          }
        },
        yAxis: {
          title: {
            text: 'Revenue'
          },
          labels: {
            format: '{value} $'
          }
        },
        tooltip: {
          crosshairs: true,
          shared: true
        },
        plotOptions: {
          spline: {
            marker: {
              radius: 4,
              lineColor: '#666666',
              lineWidth: 1
            }
          }
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || // theme
              'rgba(255,255,255,0.25)'
        },
        series: null
      }

    }
  },
  created() {
    // const startMonth = this.thisMonth()
    const yesterday = this.yesterdayDate()
    this.dates = [yesterday, yesterday]

    this.setTblHeaders()

    // this.$set(this, 'headers', this.headersByOffer)
  },
  computed: {
    refreshTable: {
      get() {
        return this.$store.getters['iface/getAffNetsRefreshStatus']
      }
    },

    loading: {
      get() {
        return this.$store.getters['iface/getAffNetsRefreshIsLoading']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_AFFNETS_TABLE_LOADING_ON)
        else
          this.$store.commit(IFACE_AFFNETS_TABLE_LOADING_OFF)
      }
    },

    onAddNewCampaign: {
      get() {
        return this.$store.getters['iface/isVisibleAffNetsAddItemWindowShow']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_AFFNETS_ADD_ITEM_WINDOW_ON)
        else
          this.$store.commit(IFACE_AFFNETS_ADD_ITEM_WINDOW_OFF)
      }
    },
    isTonicNetwork() {
      return this.$route.params.network === 'Tonic'
    },

    isDomainActiveNetwork() {
      return this.$route.params.network === 'DomainActive'
    },

    isDomainActiveNetworkFB() {
      return this.$route.params.network === 'DomainActive' && this.$route.params.account === 'Idealmedia'
    }

  },
  async mounted() {

    // console.log('mounted', this.$route.query)

    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }

    if (!('network' in this.$route.params)) {
      this.networkIsEmpty = true
    }
    if (this.$route.query.createNewFlow) {
      // console.log(this.$route)

      const query = Object.assign({}, this.$route.query);
      delete query.createNewFlow

      // await this.$router.replace({path: this.$route.path, query:{}})
      this.$router.replace({query});

      this.isVisibleEditorDialog = true

      // console.log(this.$route.query)
    }
    // else {
    //   await this.getCatalog()
    //   await this.getOffers()
    //   await this.getCampaigns()
    // }
    // console.log('this.$route.params', this.$route.params, !this.$route.params.network)
  },
  methods: {

    setTblHeaders() {
      if (this.groupBy === 'id') {
        switch (this.$route.params.network) {
          case 'Tonic':
            this.$set(this, 'headers', this.headersByIdTonic)
            break
          case 'DomainActive':
            this.$set(this, 'headers', this.headersByIdDomainActive)
            break
        }
      }
    },
    // setDateRangeText () {
    //
    // },

    async getCatalog() {
      this.loading = true
      const request = {
        network: this.$route.params.network,
        account: this.$route.params.account,
      }
      try {
        const resp = await api.affNetworkGetCatalogNetwork(request)

        this.$set(this, 'countries', resp.data.countries.map(v => {
          v.text = v.name + ' - ' + v.code
          return v
        }))

        this.$set(this, 'users', resp.data.users)

        if (resp.data.default_domain) {
          this.$set(this, 'default_domain', resp.data.default_domain)
        }

        this.loading = false

      } catch (e) {
        console.error('getDataFromApi', e)
        this.loading = false
      }
    },

    async getOffers() {
      this.loading = true

      const request = {
        network: this.$route.params.network,
        account: this.$route.params.account,
      }

      if (this.country) {
        request.country = this.country
      }

      try {
        const resp = await api.affNetworkGetOffers(request)

        this.$set(this, 'offers', resp.data.offers)
        this.totalOffers = resp.data.offers.length

        this.loading = false

      } catch (e) {
        console.error('getDataFromApi', e)
        this.loading = false
      }
    },

    async getPendingCampaigns({name}) {
      this.state = 'pending'
      this.groupBy = 'id'

      for (let i = 0; i < 10; i++) {
        if (this.state === 'pending' && this.groupBy === 'id') {

          await this.changeGroupBy(true)

          if (this.campaigns.length > 0) {
            const campaign = this.campaigns.find(c => c.name === name)
            if (campaign) {
              this.onClickRow(campaign)
              return
            } else {
              await new Promise(resolve => setTimeout(resolve, 5 * 1000))
              continue
            }
          } else {
            await new Promise(resolve => setTimeout(resolve, 5 * 1000))
            continue
          }
        } else {
          return
        }
      }
    },

    async copyKeywordsToId({id, keywords, from}) {
      this.isVisibleEditorDialog = false
      this.$nextTick(async () => {

        const row = this.campaigns.find(c => c.id === id)

        if (row) {
          this.copyKeywords = keywords
          this.copyKeywordsFromCampaignId = from
          this.onClickRow(row)
        } else {

          this.state = 'all'
          this.id = id

          await this.getCampaigns()

          const row2 = this.campaigns.find(c => {
            return c.id === id
          })

          if (row2) {
            this.copyKeywords = keywords
            this.copyKeywordsFromCampaignId = from
            this.onClickRow(row2)
          } else {
            this.errorMessage = `Not found campaign id: ${id}`
            this.isVisibleValidationErrorDialog = true
          }

        }
      })
    },

    async getCampaigns(forceUpdate) {
      this.loading = true

      const request = {
        network: this.$route.params.network,
        account: this.$route.params.account,
        state: this.state
      }

      if (this.user) {
        request.user = this.user
      }

      if (this.country) {
        request.country = this.country
      }

      if (this.offer) {
        request.offerId = this.offer.toString()
      }

      if (this.sortBy.length > 0) {
        request.sortBy = this.sortBy
      }

      if (this.sortDesc.length > 0) {
        request.sortDesc = this.sortDesc
      }

      request.limit = this.itemsPerPage
      request.page = this.page <= 0 ? 0 : this.page - 1

      if (this.dates.length === 1) {
        request.startDate = this.dates[0]
        request.endDate = this.dates[0]
      } else if (this.dates.length === 2) {
        request.startDate = this.dates[0]
        request.endDate = this.dates[1]
      } else
        throw 'Error dates'

      request.tz = this.tz

      request.groupBy = this.groupBy

      if (typeof this.id === "string") {
        const ids = this.id.replace(/\s+/g, '').trim().split(',')
        request.ids = ids
      }

      if (typeof this.id === "number") {
        request.ids = [this.id]
      }

      if (this.name) {
        request.name = this.name
      }

      if (this.link) {
        request.link = this.link
      }

      if (forceUpdate) {
        request.forceUpdate = true
      }

      try {

        // console.log('request', request)
        const resp = await api.affNetworkGetCampaigns(request)

        const p = this.page
        this.page = 0
        this.$set(this, 'campaignsTotal', resp.data.total)
        this.$set(this, 'campaigns', resp.data.data)

        if (resp.data.finalizedDates)
          this.$store.commit(IFACE_AFFNETS_SET_FINALIZEDDATES, resp.data.finalizedDates)

        if (resp.data.totalStatistic) {
          this.totalClicks = resp.data.totalStatistic.clicks
          this.totalRevenue = resp.data.totalStatistic.revenue
          this.totalLanderVisitor = resp.data.totalStatistic.landerVisitor
        } else {
          this.totalClicks = NaN
          this.totalRevenue = NaN
          this.totalLanderVisitor = NaN
        }

        let pages = Math.ceil(resp.data.total / this.itemsPerPage)
        pages = (resp.data.total % this.itemsPerPage) === 0 ? pages : pages++
        if (p > pages) {
          this.page = 1
        } else {
          this.page = p
        }

        this.loading = false

      } catch (e) {
        console.error('getDataFromApi', e)
        this.loading = false
      } finally {
        if (this.groupBy === 'offer' || this.groupBy === 'keyword') {
          this.loadStatistic()
        }
      }

    },

    async getCampaignsStatistic() {
      const items = this.campaignsCurrentItems

      this.loading = true

      if (Array.isArray(items) && items.length > 0 && items.length < 50) {
        const request = {
          network: this.$route.params.network,
          account: this.$route.params.account,
          ids: items.map(o => o.id),
          tz: this.tz
        }

        // console.log('getCampaignsStatistic', request)

        if (this.dates.length === 1) {
          request.startDate = this.dates[0]
          request.endDate = this.dates[0]
        } else if (this.dates.length === 2) {
          request.startDate = this.dates[0]
          request.endDate = this.dates[1]
        } else
          throw 'Error dates'

        try {

          const resp = await api.affNetworkGetCampaignsStatistics(request)

          // console.log('getCampaignsStatistic resp', resp)
          // console.log('getCampaignsStatistic resp campaigns', this.campaigns)

          resp.data.statistic.campaigns.buckets.map(b => {
            const i = items.find(item => item.id === b.key)
            if (i) {
              i.statistic = b
              i.clicks = b.clicks.value
              i.cpc = Math.round((b.revenue.value / b.clicks.value) * 100) / 100
              i.revenue = Math.round(b.revenue.value * 100) / 100
            }
          })

          // items.reduce((acc, item) => {
          //   console.log(item)
          //   if (!Object.prototype.hasOwnProperty.call(item, 'statistic')) {
          //     console.log(item)
          //   }
          // }, {})

          // this.$set(this, 'campaigns', resp.data.campaigns)
          this.loading = false

        } catch (e) {
          console.error('getDataFromApi', e)
          this.loading = false
        }
      }

    },

    onClickRow(row) {
      // console.log('onClickRow', row, this.paginationVisible, this.isVisibleEditorDialog)
      if (this.paginationVisible) {
        this.editCampaign = row
        this.tab = 'tab-info'
        this.isVisibleEditorDialog = true
      }

      if (this.chartIsReady) {
        this.toggleSelection(row[this.groupBy])
      }
    },


    toggleSelection(keyID) {
      this.chartIsReady = false

      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
            selectedKeyID => selectedKeyID !== keyID
        );
      } else {
        this.selectedRows = [keyID]
      }

      this.loadStatistic()

      // console.log(this.selectedRows)
    },

    onClickStatistic(row) {
      // console.log('onClickStatistic', row)
      this.editCampaign = row
      this.tab = 'tab-chart'
      this.isVisibleEditorDialog = true
    },

    async changeCountry() {
      await this.getOffers()
      await this.getCampaigns()
    },

    async changeOffer() {
      await this.getOffers()
      await this.getCampaigns()
    },

    changeUser() {
      this.getCampaigns()
    },

    changeState() {
      this.getCampaigns()
    },

    async changeGroupBy(force = false) {
      this.chartIsReady = false
      this.perPageVisible = false
      this.selectedRows = []

      switch (this.groupBy) {
        case 'id':
          this.paginationVisible = true
          this.setTblHeaders()
          this.sortBy = ['id']
          this.sortDesc = [false]
          break
        case 'user':
          this.paginationVisible = false
          this.$set(this, 'headers', this.headersByUser)
          this.sortBy = ['revenue']
          this.sortDesc = [true]
          break
        case 'country':
          this.paginationVisible = false
          this.$set(this, 'headers', this.headersByCountry)
          this.sortBy = ['revenue']
          this.sortDesc = [true]
          break
        case 'date':
          this.paginationVisible = false
          this.$set(this, 'headers', this.headersByDate)
          break
        case 'keyword':
          this.paginationVisible = false
          this.perPageVisible = true
          if (this.isDomainActiveNetwork) {
            this.$set(this, 'headers', this.headersByKeyword_DomainActive)
          } else {
            this.$set(this, 'headers', this.headersByKeyword)
          }
          this.sortBy = ['revenue']
          this.sortDesc = [true]
          break
        case 'offer':
          this.paginationVisible = false
          this.perPageVisible = true
          this.$set(this, 'headers', this.headersByOffer)
          this.sortBy = ['revenue']
          this.sortDesc = [true]
          break
      }
      await this.getCampaigns(force)
    },

    copyToClipboard(text) {
      this.$copyText(text)
      this.snackbarText = text
      this.snackbar = true
    },

    async copyToClipboardLink(item) {
      // console.log(this.$route.params)
      this.loading = true
      let text

      if (item.revenue_domain_name === null) {
        // try {
        //   const resp = await api.affNetworkGetCampaignDefaultDomain({
        //     network: this.$route.params.network,
        //     account: this.$route.params.account,
        //     state: this.state,
        //     id: item.id
        //   })
        //   if (resp.data.error) {
        //     this.errorMessage = resp.data.msg
        //     this.isVisibleValidationErrorDialog = true
        //   } else {
        //     text = `https://${resp.data.domain}/direct/${item.link}`
        //   }
        // } catch (e) {
        //   console.error('getDataFromApi', e)
        //   this.loading = false
        // } finally {
        //   this.loading = false
        // }
        if (this.default_domain.length > 0) {
          text = `https://${this.default_domain}/c/${item.link}`
        }
      } else {
        if (this.default_domain.length > 0) {
          text = `https://${this.default_domain}/c/${item.link}`
        } else {
          text = `https://${item.revenue_domain_name}/c/${item.link}`
        }
      }
      if (this.isDomainActiveNetworkFB) {
        text += '?ad_name={{ad.name}}&adset_name={{adset.name}}&campaign_name={{campaign.name}}&ad_id={{ad.id}}&atxt={{ad.text}}&tg1={tg1}&tg2={tg2}&src=fbk'
      }
      this.loading = false
      this.$copyText(text)
      this.snackbarText = text
      this.snackbar = true
    },

    onCurrentItems(v) {
      this.campaignsCurrentItems = v
      // console.log('onCurrentItems',v)
      // this.getCampaignsStatistic()
    },

    onclickOkPeekDate() {
      if (this.isToday()) {
        this.selectedFastDateRange = 0
      } else if (this.isYesterday()) {
        this.selectedFastDateRange = 1
      } else if (this.isLats7days()) {
        this.selectedFastDateRange = 2
      } else if (this.isThisMonth()) {
        this.selectedFastDateRange = 3
      } else if (this.isLastMonth()) {
        this.selectedFastDateRange = 4
      } else {
        this.selectedFastDateRange = null
      }

      this.menu = false
      this.getCampaigns()
    },

    onChangeId() {
      // this.getCampaignsStatistic()
      this.getCampaigns()
    },

    onChangeName() {
      // this.getCampaignsStatistic()
      this.getCampaigns()
    },

    onChangeLink() {
      // this.getCampaignsStatistic()
      this.getCampaigns()
    },

    todayDate() {
      // const c = DateTime.local().setZone('America/Los_Angeles').toISODate()
      // console.log('cccccccccccc', c, )
      return DateTime.local().setZone(this.tz).toISODate()
    },

    yesterdayDate() {
      return DateTime.local().setZone(this.tz).endOf('day').plus({days: -1}).toISODate()
    },

    minus4days() {
      return DateTime.local().setZone(this.tz).endOf('day').plus({days: -4}).toISODate()
    },


    minus7days() {
      return DateTime.local().setZone(this.tz).endOf('day').plus({days: -7}).toISODate()
    },

    thisMonth() {
      return DateTime.local().setZone(this.tz).startOf('month').toISODate()
    },

    isToday() {
      if (this.dates.length === 1)
        return this.todayDate() === this.dates[0]
      if (this.dates.length === 2)
        return this.todayDate() === this.dates[0] && this.todayDate() === this.dates[1]

      return false
    },

    isYesterday() {
      if (this.dates.length === 1)
        return this.yesterdayDate() === this.dates[0]
      if (this.dates.length === 2)
        return this.yesterdayDate() === this.dates[0] && this.yesterdayDate() === this.dates[1]

      return false
    },

    isLats3days() {
      if (this.dates.length === 2)
        return this.minus4days() === this.dates[0] && this.yesterdayDate() === this.dates[1]
      return false
    },

    isLats7days() {
      if (this.dates.length === 2)
        return this.minus7days() === this.dates[0] && this.todayDate() === this.dates[1]
      return false
    },

    isThisMonth() {
      if (this.dates.length === 2)
        return this.thisMonth() === this.dates[0] && this.todayDate() === this.dates[1]
      return false
    },

    isLastMonth() {
      const startMonth = DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString()
      const endMonth = DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()

      if (this.dates.length === 2)
        return startMonth === this.dates[0] && endMonth === this.dates[1]
      return false
    },

    onClickFastDateInterval({value}) {
      switch (value) {
        case 'today' :
          this.dates = [this.todayDate()]
          break
        case 'lats3days' :
          this.dates = [this.minus4days(), this.yesterdayDate()]
          break
        case 'lats7days' :
          this.dates = [this.minus7days(), this.todayDate()]
          break
        case 'yesterday' :
          this.dates = [this.yesterdayDate()]
          break
        case 'thisMonth' :
          this.dates = [this.thisMonth(), this.todayDate()]
          break
        case 'lastMonth' :
          this.dates = [
            DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString(),
            DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()]

          break
        default:
          throw `Unknown ${value}`
      }

      this.getCampaigns()
      this.menu = false
    },

    loadStatistic() {

      // this.chartIsReady = false

      this.$nextTick(() => {
        if (this.selectedRows.length === 0) {
          this.highchartsOptions = this.highchartsForAllOptions
          this.highchartsOptions.series = []
          const categories = {}

          this.campaigns.forEach(c => {
            const data = c.statistic.days.buckets.map(b => {
              const revenue = Math.round(b.revenue.value * 100) / 100
              categories[b.key] = DateTime.fromMillis(b.key, {zone: this.tz}).toSQLDate()
              return revenue
            })
            const row = {
              name: c[this.groupBy],
              data,
              tooltip: {
                valueSuffix: ' $'
              }
            }
            this.highchartsOptions.series.push(row)
          })

          this.highchartsOptions.xAxis.categories = Object.entries(categories).map(([, v]) => v).sort()

        } else {
          if (this.isDomainActiveNetwork) {
            this.highchartsOptions = this.highchartsForSingleOptions_DA
          } else {
            this.highchartsOptions = this.highchartsForSingleOptions
          }

          this.highchartsOptions.series[0].data = []
          this.highchartsOptions.series[1].data = []
          // this.highchartsOptions.series[2].data = []

          const categories = {}

          const target = this.campaigns.find(c => c[this.groupBy] === this.selectedRows[0])

          const revenueData = []
          const clicksData = []
          const cpcData = []
          const ctrData = []

          target.statistic.days.buckets.forEach(b => {
            const revenue = Math.round(b.revenue.value * 100) / 100

            categories[b.key] = DateTime.fromMillis(b.key, {zone: this.tz}).toSQLDate()

            revenueData.push(revenue)
            clicksData.push(b.clicks.value)
            cpcData.push(Math.round((revenue / b.clicks.value) * 100) / 100)

            if (this.isDomainActiveNetwork) {
              ctrData.push(b.ctr.value)
            }
          })


          this.highchartsOptions.series[0].data = revenueData
          this.highchartsOptions.series[1].data = cpcData
          this.highchartsOptions.series[2].data = clicksData

          if (this.isDomainActiveNetwork) {
            this.highchartsOptions.series[3].data = ctrData
          }

          this.highchartsOptions.xAxis[0].categories = Object.entries(categories).map(([, v]) => v).sort()
        }

        this.chartIsReady = true

      })
    },
  },

  watch: {
    isVisibleEditorDialog(v) {
      if (!v) {
        if (this.copyKeywords.length > 0) {
          this.copyKeywords = []
        }
        this.editCampaign = null
      }
    },

    page() {
      this.getCampaigns()
    },
    itemsPerPage(value) {
      this.$set(this.options, 'itemsPerPage', parseInt(value, 10))
      this.getCampaigns()
    },
    sortBy() {
      this.getCampaigns()
    },
    sortDesc() {
      this.getCampaigns()
    },

    dates() {
      if (this.isToday()) {
        this.dateRangeText = 'Today'
        return
      }
      if (this.isYesterday()) {
        this.dateRangeText = 'Yesterday'
        return
      }

      if (this.isLats3days()) {
        this.dateRangeText = 'Lats 3 days'
        return
      }

      if (this.isLats7days()) {
        this.dateRangeText = 'Lats 7 days'
        return
      }
      if (this.isThisMonth()) {
        this.dateRangeText = 'This month'
        return
      }
      if (this.isLastMonth()) {
        this.dateRangeText = 'Last month'
        return
      }

      this.dateRangeText = this.dates.join(' ~ ')
    },

    refreshTable(value) {
      if (value) {
        this.getCampaigns(true)
      }

    },
    loading(value) {
      if (!value) {
        this.$store.commit(IFACE_AFFNETS_REFRESH_OFF)
      }
    },
    onAddNewCampaign() {
      this.isVisibleEditorDialog = true
    },

    id(val) {
      if (this.$route.query.id && val === null) {
        delete this.$route.query.id
      }

    },

    '$route.params.account': {
      handler: async function () {
        this.setTblHeaders()
        await this.getCatalog()
        await this.getOffers()
        await this.getCampaigns()
      },
      deep: true,
      immediate: true

    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--headerHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>